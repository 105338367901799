<template>
  <div>
    <div class="contact" v-if="!dialogVisible">
      <div class="contactimg">
        <img :src="bannerimg" width="100%" height="100%">
      </div>
      <div class="contactinfo">
        <div class="product_title">
          <div class="product_tit">
            <div class="tit1">联系我们</div>
            <div class="tit2"></div>
          </div>
        </div>
        <ul>
          <li>
            <div class="top">
              <img src="../../assets/images/09联系我们/企业名称.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1">企业名称</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p>南京梦宇三维技术有限公司</p>
              <p>Nanjing DreamUniverssal 3D Technologies Inc.</p>
            </div>
          </li>
          <li @click="dialogVisible=true">
            <div class="top">
              <img src="../../assets/images/09联系我们/公司地址.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1">公司地址</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p>南京市雨花台区宁双路19号软件谷云密城B幢六楼</p>

            </div>
          </li>
          <li>
            <div class="top">
              <img src="../../assets/images/09联系我们/联系电话.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1">联系电话</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p>025-86386606</p>

            </div>
          </li>
          <li style="margin-bottom:10px">
            <div class="top">
              <img src="../../assets/images/09联系我们/邮箱.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1">邮箱</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p>fang.tong@mengyu-3d.com</p>

            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="fixed" v-else>
      <div class="fixed-box">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-size:0.3rem">地图</span>
            <img src="@/assets/images/09联系我们/错误.png" @click="dialogVisible=false" alt="">

          </div>
          <iframe width="100%" height="350" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://surl.amap.com/dgDW60r40Aa"></iframe>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      content: "",
      addressimg: "",
      dialogVisible: false,
    };
  },
  mounted() {
    this.getcompany();
  },
  created() {},
  methods: {
    //获取公司简介
    async getcompany() {
      let params = {
        barId: 11,
      };
      const data = await getbannerdetail(params);
      this.bannerimg = "http://36.152.65.166:10008" + data.data.image;
      // this.content = data.data.children[0].content;
      // this.addressimg =
      //   "http://36.152.65.166:10008" + data.data.children[0].image;
    },
  },
};
</script>

<style>
</style>

<style lang="scss" scoped>
.fixed {
  padding: 0.2rem;
  height: calc(100vh - 170px);
  // padding: 0rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .fixed-box {
    padding: 0.1rem;
    // border: 0.02rem solid #0088ea;
    border-radius: 0.1rem;
    .clearfix {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .top {
      // width: 1rem;
      // height: 0.7rem;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.2rem;
    }
  }
  // pointer-events: none; /* 设置遮罩层不接收用户的鼠标和触摸事件 */
}
.contact {
  // border: solid;
  height: calc(100vh - 170px);

  .contactimg {
    width: 100%;
    // height: 2.46rem;
    display: flex;
    align-items: center;
    justify-content: center;

    // background: url("../../assets/images/09联系我们/tu.jpg") no-repeat;
    // background-size: 100% 100%;
    .imgtit {
      text-align: center;
      color: #fff;
      .big {
        font-size: 0.48rem;
        font-weight: bold;
      }
      .sm {
        font-size: 0.24rem;
      }
    }
  }

  .contactinfo {
    ul {
      width: 6rem;
      // height: 6rem;
      padding: 0;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      li {
        margin-top: 0.3rem;
        cursor: pointer;
        width: 5rem;
        height: 3rem;
        border: 0.04rem solid #e8eaf2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .top {
          width: 0.7rem;
          height: 0.7rem;
          display: flex;
          // margin-bottom: 0.1rem;
          img {
            width: 100%;
            // height: 100%;
          }
        }
        .mid {
          font-size: 0.18rem;
          font-weight: bold;
          .midtitle {
            display: flex;
            flex-direction: column;
            align-items: center;
            .midtit2 {
              margin-top: 0.1rem;
              width: 0.6rem;
              height: 0.02rem;
              background-color: #e8eaf2;
            }
          }
        }
        .bot {
          height: 30%;
          text-align: center;
          font-size: 0.2rem;
          font-weight: bold;
        }
      }
      li:hover {
        background-color: #e8eaf2;
        .mid {
          .midtitle {
            .midtit2 {
              background-color: #0088ea;
            }
          }
        }
      }
    }
  }
}
.product_title {
  width: 7.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    .tit1 {
      font-size: 0.3rem;
      font-weight: bold;
      margin-bottom: 0.13rem;
    }
    .tit2 {
      width: 0.6rem;
      height: 0.04rem;
      background-color: #0088ea;
    }
  }
}
</style>